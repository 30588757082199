import { EventEmitter, Injectable, inject } from '@angular/core';
import {OEXTab} from '../controls/tabs/tabs';
import {environment} from '../../environments/environment';
import {NavigationEnd, Router} from '@angular/router';

export interface IBreadcrumb {
  id: string;
  label: string;
  link?: string;
  isAction?: boolean;
  icon?: string;
}

export const BREADCRUMBS: { [key: string]: IBreadcrumb } = {
  BackToEditing: {
    id: 'back',
    label: 'Back to editing',
    isAction: true,
    icon: 'assets/img/arrow-left.svg'
  },
  Home: {
    id: 'home',
    label: 'Home',
    link: '/'
  },
  Applications: {
    id: 'apps',
    label: environment.pd ? 'Products' : 'Applications',
    link: environment.pd ? '/solutions' : '/'
  }
};

export const BREADCRUMB_MAKE = (label: string, link?: string): IBreadcrumb => {
  return {id: label, label, link};
};

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private router = inject(Router);

  onBreadcrumbPressed = new EventEmitter<IBreadcrumb>();
  onBreadcrumbsChanged = new EventEmitter<IBreadcrumb[]>();
  previousUrl: string;

  constructor() {
    this.previousUrl = this.router.url;
    this.bindEvents();
  }

  setBreadcrumbs(list?: IBreadcrumb[], includeHome = true) {
    if (!list) {
      this.onBreadcrumbsChanged.emit([]);
      return;
    }
    this.onBreadcrumbsChanged.emit([...(includeHome ? [BREADCRUMBS.Home] : []), ...list]);
  }

  private bindEvents() {
    // Reset breadcrumbs on any navigation
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        const currentUrl = e.urlAfterRedirects;
        if (this.isRoutePathChanged(currentUrl)) {
          this.setBreadcrumbs();
        }
        this.previousUrl = currentUrl;
      }
    });
  }

  isRoutePathChanged(currentUrl: string): boolean {
    const previousRoutePath = this.getRoutePath(this.previousUrl);
    const currentRoutePath = this.getRoutePath(currentUrl);
    return previousRoutePath !== currentRoutePath;
  }

  getRoutePath(url: string): string {
    const queryParamIndex = url.indexOf('?');
    return (queryParamIndex !== -1) ? url.slice(0, queryParamIndex) : url;
  }
}
