<!-- Block with logo and title -->
<div class="info-block">
  <div class="app-block">
    <!-- Logo -->
    <div class="logo">
      <img [src]="image() ? image() : (model().ImageURL || '/assets/img/package.svg')">
    </div>
    <!-- Title -->
    <div class="title">
      <h1>
        {{model().Name}}

        <ng-content select="title"></ng-content>
        <!-- Edit button -->
        <!--<a class="btn-edit" *ngIf="model._id !== 'new' && (model.UserID.login === auth.getUser())" [routerLink]="'/portal/products/' + model.NameWithoutSpaces + '/edit'">
        <img src="assets/img/edit-outline.svg">
      </a>-->
      <!-- Actions -->
      @if (isOwner() || auth.isAdmin) {
        <menu-button [changeColorOnHover]="true" icon="assets/img/btn-more-circle.svg" class="transparent" xPosition="before" (select)="onAction($event)" [items]="getActions()"></menu-button>
      }
      <!-- Status -->
      <!--<span [class]="'mp-status mp-tile-status-' + status.value" *ngIf="model.isOwner">{{getStatusText()}}</span>-->
    </h1>

    <!-- User and Company -->
    <div class="user-block">
      @if (pd.enabled) {
        <!-- Avatar -->
        <oex-avatar
          [name]="model().PublisherID?.Name || ''"
          [small]="true"
        ></oex-avatar>
        <span>by&nbsp;</span>
        <!-- Publisher link -->
        <a class="mp-link secondary" [routerLink]="(st.isMarketplace ? mpRoute : '') + '/company/' + model().PublisherID?.NameWithoutSpaces">{{model().PublisherID?.Name}}</a>
      } @else {
        <!-- Avatar -->
        <ui-avatar-list [items]="avatarList()" yPosition="bottom"></ui-avatar-list>
        <!--<oex-avatar
          [image]="model().UserID.avatar || ''"
          [name]="model().UserID.firstName || ''"
          [small]="true"
        ></oex-avatar>-->
        <span>by&nbsp;</span>
        <!-- User link -->
        <a class="mp-link secondary" [routerLink]="'/user/' + model().UserID.firstName + '/' + model().UserID.individualKey">{{model().UserID.firstName}}</a>
        <!-- Others -->
        @if (model().collaborators.length > 1) {
          <span>&nbsp;and {{model().collaborators.length - 1}} others</span>
        }
        <!-- Publisher link -->
        <!--@if (model.PublisherID) {
          <span>&nbsp;on behalf of&nbsp;</span>
          <a class="mp-link secondary"
             [routerLink]="(st.isMarketplace ? mpRoute : '') + '/company/' + model.PublisherID.NameWithoutSpaces">{{model.PublisherID.Name}}</a>
        }-->
      }



      <!--&lt;!&ndash; pd link &ndash;&gt;
      @if (pd.enabled && model.PublisherID) {
        <a class="mp-link secondary"
        [routerLink]="(st.isMarketplace ? mpRoute : '') + '/company/' + model.PublisherID.NameWithoutSpaces">{{model.PublisherID.Name}}</a>
      }
      &lt;!&ndash; user link &ndash;&gt;
      @if (!model.PublisherID) {
        <a class="mp-link secondary"
        [routerLink]="'/user/' + model.UserID.firstName + '/' + model.UserID.individualKey">{{model.UserID.firstName}}</a>
      }-->
    </div>
  </div>
</div>

<!-- Disclaimer -->
@if (!pd.enabled) {
  @if ((!model().PublisherID || model().Official !== 1) && model().Official !== 1) {
    <div class="disclaimer">
      <div class="frame">
        <img src="assets/img/warning-orange.svg">
      </div>
      This application is not supported by InterSystems Corporation. Please be notified that you use it at your own risk.
    </div>
  }
  <!-- Official sample -->
  @if (model().Official === 1) {
    <div class="disclaimer">
      <div class="frame">
        <img src="/assets/img/is-logo.svg" class="is-logo">
      </div>
      Supported by InterSystems Corporation
    </div>
  }
}
</div>

<!-- Block with counters and actions -->
<div class="action-block">
  <oex-stats-bar [items]="stats" [class.marketplace]="st.isMarketplace"></oex-stats-bar>

  <!-- Actions -->
  <div class="actions">
    @if (!st.isMarketplace) {
      <div class="btn-gray" (click)="onWatchClicked()" matTooltip="Click here to follow updates for this app. You'll receive notifications about new releases.">
        <img src="assets/img/subscribe.svg" [class.active]="model().IsFollow === 1">
        <span>{{(model().Follows || 0) | numberFormat}}</span>
      </div>
    }
    <div class="btn-gray" (click)="onStarClicked()" matTooltip="Click to save this app to your list of favorites for easy access later.">
      <img src="assets/img/bookmark.svg" [class.active]="model().IsStar === 1">
      <span>{{(model().Stars || 0) | numberFormat}}</span>
    </div>
    <!-- Share button -->
    <oex-share-button [name]="model().Name || ''" [desc]="model().Description || ''"></oex-share-button>
    <!-- Info button -->
    <div class="btn-gray btn-info" (click)="onInfoClicked($event)">
      <img src="assets/img/info.svg">
    </div>
    <button
      class="btn-primary"
      [class.marketplace]="st.isMarketplace"
      (click)="download($event)"
      [disabled]="(model().inquirySent === 1) || (st.isMarketplace && (isOwner() || isCompanyOwner()))"
    >{{downloadButtonText}}</button>
  </div>
</div>
