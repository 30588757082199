import { ChangeDetectorRef, Component, OnInit, input, inject } from '@angular/core';
import {HttpService} from '../../../services/httpService';
import {RouterLink} from '@angular/router';
import {CardComponent} from '../../../controls/card/card';
import {MatProgressBar} from '@angular/material/progress-bar';

export interface IPackageDependencyItem {
  package_name: string;
  nws?: string;
  user_key?: string;
  user_name?: string;
}

export interface IPackageDependency {
  version: string;
  dependencies: IPackageDependencyItem[];
}

@Component({
    selector: 'oex-deps-list',
    templateUrl: './dependencies-list.html',
    imports: [
        RouterLink,
        CardComponent,
        MatProgressBar
    ],
    styleUrls: ['./dependencies-list.scss']
})
export class DependenciesListComponent implements OnInit {
  private http = inject(HttpService);
  private cd = inject(ChangeDetectorRef);

  readonly nws = input('');
  isLoading = false;
  data: IPackageDependency[] = [];

  ngOnInit() {
    void this.requestData();
  }

  private async requestData() {
    this.isLoading = true;
    return this.http.request(`/mpapi/packages/get/${this.nws()}/dependencies`)
      .then(data => {
        this.data = data?.versions;
      })
      .finally(() => {
        this.isLoading = false;
        this.cd.detectChanges();
      });
  }
}
