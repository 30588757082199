@if (!isLoading) {
  <oex-wide-button class="tablet-vertical" text="New pull request" [href]="model().Github + '/compare'">
    Open pull requests ({{list.length}})
  </oex-wide-button>
  @for (p of list; track trackByIndex($index, p)) {
    <div class="item">
      <a class="mp-link secondary" [href]="p.url" target="_blank">
        <img src="assets/img/pull-request-icon.svg">
        {{p.title}}
      </a>
      <span class="date">opened {{p.created_UI}}</span>
    </div>
  }
}
