import { ChangeDetectionStrategy, ChangeDetectorRef, Component, LOCALE_ID, OnInit, input, inject } from '@angular/core';
import {HttpService} from '../../../services/httpService';
import {OEXMarkdown} from '../../../services/markdown';
import {DatePipe} from '@angular/common';
import {RouterLink} from '@angular/router';
import {CardComponent} from '../../../controls/card/card';
import {IAppQualityReport, IAppQualityStatus} from '../../../admin/app-quality/admin-app-quality';


@Component({
  selector: 'oex-app-report-list',
  templateUrl: 'app-report-list.html',
  styleUrls: ['app-report-list.scss'],
  imports: [
    RouterLink,
    CardComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppReportList implements OnInit {
  private http = inject(HttpService);
  private cd = inject(ChangeDetectorRef);
  private locale = inject(LOCALE_ID);

  readonly nws = input('');
  isLoading = true;
  data: IAppQualityReport[] = [];
  workingStatusText: { [id: string]: string } = {};
  private workingStatuses: IAppQualityStatus[] = [];
  private datePipe: DatePipe;

  constructor() {
    const locale = this.locale;

    this.datePipe = new DatePipe(locale);
  }

  ngOnInit() {
    Promise.all([
      this.requestWorkingStatuses(),
      this.requestData()
    ])
      .finally(() => {
        this.isLoading = false;
        this.cd.detectChanges();
      });
  }

  async requestWorkingStatuses() {
    return this.http.request('/mpapi/qualitymanagement/tasks/working_statuses')
      .then(d => {
        this.workingStatuses = d.items;
        this.workingStatusText = this.workingStatuses.reduce((obj, item) => {
          obj[item.id] = item.name;
          return obj;
        }, {});
      });
  }

  private async requestData() {
    return this.http.request(`/mpapi/qualitymanagement/reports/${this.nws()}`)
      .then(d => {
        this.data = d?.items;
        this.prepareItems();
        this.cd.detectChanges();
      });
  }

  private prepareItems() {
    if (!this.data?.length) {
      return;
    }
    this.data.forEach(r => {
      r.last_updateUI = this.datePipe.transform(r.last_update?.replace(/\s/, 'T'), 'dd MMM, yyyy') ?? '';
      r.commentMd = OEXMarkdown.parse(r.comment);
    });
  }
}
