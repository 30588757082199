@for (r of data; track r) {
  <oex-card
    [minHeight]="50"
    [collapsible]="true"
    >
    <!-- Header -->
    <div ngProjectAs="header" class="header">
      <span class="status" [class.success]="r.working_status == 1">{{workingStatusText[r.working_status ?? '']}}</span>Comments sent to the developer
      <!-- Date -->
      @if (r.last_updateUI) {
        <span class="date-txt">{{r.last_updateUI}}</span>
      }
    </div>
    <!-- Details -->
    <div class="grid">
      <div>
        <label>Responsible</label>
        <a class="mp-link secondary" [routerLink]="'/user/' + r.moderator.name + '/' + r.moderator.individualKey">{{r.moderator.name}}</a>
      </div>
      <div>
        <label>Release after report</label>
        <span class="badge" [class.badge-green]="r.release_after_report === 1">{{r.release_after_report === 1 ? 'Yes' : 'No'}}</span>
      </div>
      <div>
        <label>Approved by admin</label>
        <span class="badge" [class.badge-green]="r.approved === 1">{{r.approved === 1 ? 'Yes' : 'No'}}</span>
      </div>
      <!--<div>
      <label>Report sent</label>
      {{r.last_updateUI}}
    </div>
    <div>
      <label>Status</label>
      {{workingStatusText[r.working_status]}}, comments sent to the developer
    </div>-->
    <div class="col-3">
      <label>Comment</label>
      <div class="mp-markdown" [innerHTML]="r.commentMd"></div>
    </div>
  </div>
</oex-card>
}
