<!-- Add button -->
@if (canEdit()) {
  <button class="btn" (click)="showUploadDialog()">Add screenshot</button>
}

<!-- Screenshots -->
@for (m of media(); track m; let idx = $index) {
  <div
    class="preview"
    (click)="openScreenshot(idx)">
    <img [src]="m.preview">
    @if (canEdit()) {
      <div (click)="deleteAsset(idx, $event)" class="btn-del"></div>
    }
  </div>
}

<!-- Uploader -->
<input #uploader type="file" accept="image/*" enctype="multipart/form-data" (change)="startUpload($event)" hidden>
