<!-- Progress spinner -->
@if (isLoading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

@if (!isLoading) {
  <oex-card
    [collapsible]="true"
    [collapsed]="false"
    >
    <!-- Header -->
    <div class="header" ngProjectAs="header">
      Throughout all versions <span class="txt-count">Dependants ({{data.length || 0}})</span>
    </div>
    @for (d of data; track d) {
      <div class="dep-item">
        <!-- Package name link -->
        <strong>
          @if (d.nws) {
            <a class="mp-link secondary" [routerLink]="'/package/' + d.nws">{{d.package_name}}</a>
          } @else {
            {{d.package_name}}
          }
        </strong>
        <!-- Company/User name link -->
        @if (d.user_key && d.user_name) {
          <span>&nbsp;by&nbsp;</span>
          <a class="mp-link secondary" [routerLink]="'/user/' + d.user_name + '/' + d.user_key">{{d.user_name}}</a>
        }
      </div>
    }
  </oex-card>
}
