import {AfterViewInit, Component, OnInit, input, model} from '@angular/core';
import {IScreenshot, MediaListComponent} from './media-list';
import {DomSanitizer} from '@angular/platform-browser';

export const YOUTUBE_EMBED_URL = 'https://www.youtube.com/embed/';

@Component({ standalone: true,
  selector: 'media-preview',
  templateUrl: 'media-preview.html',
  styleUrls: ['media-preview.scss']
})
export class MediaPreviewComponent implements OnInit {
  readonly screenshots = input<IScreenshot[]>([]);
  readonly index = model(0);
  readonly videoUrl = input('');


  constructor() {
  }

  ngOnInit(): void {
  }

  /**
   * Shows next or previous image
   * @param delta
   */
  gotoPreview(delta: number) {
    this.index.update(idx => idx + delta);
    if (this.index() > this.screenshots().length) {
      this.index.set(this.screenshots().length - 1);
    }
    if (this.index() < 0) {
      this.index.set(0);
    }
  }
}
