import { Component, ElementRef, OnInit, viewChild, input, model, inject } from '@angular/core';
import {HttpService} from '../../../services/httpService';
import {AuthService} from '../../../services/authService';
import {ModalData, ModalService} from '../../../services/modal.service';
import {MediaPreviewComponent} from './media-preview';
import {IPackageScreenshot, URLObject} from '../../../services/packageTypes';


export interface IScreenshot {
  id: number;
  preview: string;
  url: string;
}

@Component({
    selector: 'media-list',
    templateUrl: 'media-list.html',
    imports: [],
    styleUrls: ['media-list.scss']
})
export class MediaListComponent implements OnInit {
  private http = inject(HttpService);
  private auth = inject(AuthService);
  private modal = inject(ModalService);

  readonly uploader = viewChild.required<ElementRef>('uploader');
  readonly canEdit = input(false);
  readonly media = model<IPackageScreenshot[]>([]);
  readonly video = input<URLObject[]>([]);
  readonly packageId = input(-1);
  isUploading = false;

  /**
   * Returns youtube video key
   * @param url
   */
  static getVideoKey(url: string) {
    const u = url.toLowerCase();

    // Check for "youtu.be" version of url, eg. https://youtu.be/oIxESj7z0a8
    if (u.indexOf('youtu.be') !== -1) {
      return url.split('/').pop();
    }

    // Check for embed version of url, eg. https://www.youtube.com/embed/oIxESj7z0a8
    if (u.indexOf('/embed/') !== -1) {
      return url.split('/').pop();
    }

    // Check for normal version of url, eg. https://www.youtube.com/watch?v=oIxESj7z0a8
    if (u.indexOf('watch?v=') !== -1) {
      return url.split('watch?v=').pop()?.split('&').shift();
    }
    console.warn('Incorrect youtube link: ', url, '. Please use link like this:  https://youtu.be/oIxESj7z0a8, https://www.youtube.com/embed/oIxESj7z0a8, https://www.youtube.com/watch?v=oIxESj7z0a8');
  }

  ngOnInit() {
    if (!this.media()) {
      this.media.set([]);
    }
  }

  /**
   * Opens screenshot preview
   * @param index
   */
  openScreenshot(index: number) {
    this.modal.show(<ModalData>{
      cancel: true,
      compModel: {
        component: MediaPreviewComponent,
        props: {
          index,
          screenshots: this.media(),
          videoUrl: this.video()
        },
        styles: {
          nativeParent: [{className: 'modal-no-limits'}]
        }
      },
      message: '',
      buttons: []
    });
  }

  /**
   * Adds new screenshot to list
   * @param scr
   */
  addNewScreenshot(scr: IPackageScreenshot) {
    const media = this.media();
    if (!media) {
      this.media.set([]);
    }
    if (!scr.preview) {
      scr.preview = scr.url;
    }
    media.push(scr);
  }

  /**
   * Deletes screenshot
   * @param scrId
   * @param e
   */
  deleteAsset(idx: number, e: MouseEvent) {
    const scr = this.media()[idx];
    e.stopPropagation();
    this.http.request(`/mpapi/packages/screenshots/${scr.id}`, 'DELETE')
      .then(() => {
        this.media().splice(idx, 1);
      })
      .catch((e) => {
        console.error(`Can't delete screenshot. `, e);
      });
  }

  /**
   * Shows upload dialog
   */
  showUploadDialog() {
    this.uploader().nativeElement.click();
  }

  // TODO: refactor and use one method for all uploading routines
  /**
   * Starts uploading process
   * @param e
   */
  startUpload(e: any) {
    this.isUploading = true;
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = event => {
      this.uploader().nativeElement.value = null;
      const formData = new FormData();
      formData.append('image', file);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', this.http.getUrl(`/mpapi/packages/screenshots/${this.packageId()}`), true);
      xhr.upload.onloadend = _ => {
        this.isUploading = false;
      };
      xhr.upload.onerror = _ => {
        this.isUploading = false;
      };
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          this.isUploading = false;
          let scr;
          try {
            scr = JSON.parse(xhr.responseText);
          } catch (e) {
          }
          if (scr && !scr.errors) {
            this.addNewScreenshot(scr);
          }
        }
      };
      xhr.send(formData);
    };
    reader.readAsBinaryString(file);
  }
}
