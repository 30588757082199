import { ChangeDetectionStrategy, ChangeDetectorRef, Component, LOCALE_ID, OnInit, input, inject } from '@angular/core';
import {HttpService} from '../../../services/httpService';
import {IPackageData} from '../../../services/packageTypes';
import {DatePipe} from '@angular/common';
import {WideButtonComponent} from '../../../controls/wide-button/wide-button.component';
import {PortalService} from '../../../portal/services/portal-service';

interface IPullRequestData {
  id: number;
  title: string;
  url: string;
  created: string;
  // For UI
  created_UI?: string;
}

@Component({
  selector: 'oex-pull-requests-list',
  templateUrl: './pull-requests-list.component.html',
  styleUrls: ['./pull-requests-list.component.scss'],
  imports: [
    WideButtonComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PullRequestsListComponent implements OnInit {
  private http = inject(HttpService);
  private cd = inject(ChangeDetectorRef);
  private ps = inject(PortalService);
  private locale = inject(LOCALE_ID);

  readonly model = input.required<IPackageData>();
  list: IPullRequestData[] = [];
  isLoading = true;
  private datePipe: DatePipe;

  constructor() {
    const locale = this.locale;

    this.datePipe = new DatePipe(locale);
  }

  trackByIndex = (index: number, r: any) => index;

  ngOnInit() {
    this.requestData();
  }

  private requestData() {
    this.ps.setProgress(true);
    this.isLoading = true;
    this.http.request(`/mpapi/packages/get/${this.model()?.NameWithoutSpaces}/pull_requests`)
      .then(data => {
        this.list = data;
        this.prepareData();
      })
      .finally(() => {
        this.isLoading = false;
        this.ps.setProgress(false);
        this.cd.detectChanges();
      });
  }

  private prepareData() {
    this.list.forEach(d => {
      d.created_UI = this.datePipe.transform(d.created.replace(/\s/, 'T'), 'dd MMM, yyyy') ?? '';
    });
  }
}
