import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, input, OnDestroy, OnInit, signal, output, inject } from '@angular/core';
import {STATUS_NAMES, TileBaseComponent} from '../tile/tile.base';
import {PartnersDirectoryService} from '../../services/partnersDirectory';
import {AuthService} from '../../services/authService';
import {IPackageData} from '../../services/packageTypes';
import {ConfigService} from '../../services/configService';
import {IStatItem, StatsBarComponent} from '../stats-bar/stats-bar.component';
import {Subscription} from 'rxjs/internal/Subscription';
import {IMenuButtonAction, MenuButtonComponent} from '../menu-button/menu-button';
import {Router, RouterLink} from '@angular/router';
import {ModalData, ModalService} from '../../services/modal.service';
import {HttpService} from '../../services/httpService';
import {DialogService} from '../../services/dialogService';
import {ShareButtonComponent} from '../share-button/share-button.component';
import {NumberFormatPipe} from '../../services/number-format.pipe';
import {AvatarComponent} from '../avatar/avatar';
import {PortalService} from '../../portal/services/portal-service';
import {SiteTrackerService} from '../../services/site-tracker.service';
import {ROUTE_MARKETPLACE} from '../../services/global-types';
import {AvatarListComponent, IAvatarData} from '../../../oex-ui-kit/components/avatar-list/avatar-list.component';

export class IAvatar {
  name = '';
  image = '';
}

@Component({
    selector: 'oex-headline',
    templateUrl: 'headline.html',
    styleUrls: ['headline.scss', '../tile/status.scss'],
    imports: [
        ShareButtonComponent,
        NumberFormatPipe,
        StatsBarComponent,
        RouterLink,
        AvatarComponent,
        MenuButtonComponent,
        AvatarListComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadlineComponent implements OnInit, OnDestroy {
  pd = inject(PartnersDirectoryService);
  private cd = inject(ChangeDetectorRef);
  private router = inject(Router);
  private modal = inject(ModalService);
  private http = inject(HttpService);
  private ps = inject(PortalService);
  private ds = inject(DialogService);
  st = inject(SiteTrackerService);
  auth = inject(AuthService);

  model = input.required<IPackageData>();

  // Avatar list
  avatarList = computed(() => {
    const m = this.model();
    if (!m) {
      return [];
    }
    return m.collaborators.map(c => ({
      name: c.firstName,
      image: c.avatar,
      url: `/user/${c.firstName}/${c.individualKey}`
    }) as IAvatarData);
  });


  readonly image = input('');
  readonly status = input({
    show: false,
    value: ''
});
  stats: IStatItem[] = [];

  readonly onWatch = output();
  readonly onStar = output();
  readonly onDownload = output<MouseEvent>();
  readonly onInfo = output<MouseEvent>();
  private subLogin?: Subscription;

  initStats() {
    const m = this.model();
    const reviews: IStatItem = {
      label: (m?.Reviews?.length || 0) + ' reviews',
      value: m?.Rating || 0,
      icon: 'assets/img/star-blue.svg'
    };
    const awards: IStatItem = {
      label: 'Awards',
      value: m?.Rewards?.length || 0
    };
    const views: IStatItem = {
      label: 'Views',
      value: m?.Views || 0
    };
    const ipm: IStatItem = {
      label: 'IPM installs',
      value: m?.PMInstalls || 0
    };
    this.stats = this.pd.enabled ? [reviews, views] : [reviews, awards, views, ipm];
  }

  ngOnInit() {
    // Detect changes after login
    this.subLogin = this.auth.onLogin.subscribe(l => {
      this.cd.detectChanges();
    });

    this.initStats();
  }

  detectChanges() {
    this.cd.detectChanges();
  }

  onWatchClicked() {
    this.onWatch.emit();
  }

  onStarClicked() {
    this.onStar.emit();
  }

  /*onLeaveClicked() {
    this.onLeave.emit();
  }*/


  /**
   * Returns status text
   * @param value
   */
  getStatusText(): string {
    const status = TileBaseComponent.getStatus(this.model()?.Published, this.model()?.NeedApprove);
    return STATUS_NAMES[status];
  }

  /**
   * Checks is there is some ng-content
   */

  /* hasContent(): boolean {
     if (this.markdown) { return true; }
     if (!this.col1 || !this.col2) { return false; }
     return !!((this.col1.nativeElement as HTMLElement).innerText || (this.col2.nativeElement as HTMLElement).innerText);
   }

   col0empty(): boolean {
     return !(this.col0.nativeElement as HTMLElement).innerText;
   }

   col1empty(): boolean {
     return !(this.col1.nativeElement as HTMLElement).innerText;
   }

   col2empty(): boolean {
     return !(this.col2.nativeElement as HTMLElement).innerText;
   }*/
  mpRoute = `/${ROUTE_MARKETPLACE}`;

  get downloadButtonText() {
    if (this.st.isMarketplace) {
      return this.model().inquirySent === 1 ? 'Request is pending' : 'Get It Now';
    }
    return ConfigService.getDownloadButtonText(this.model());
  }

  download(e: MouseEvent) {
    this.onDownload.emit(e);
  }

  onInfoClicked(e: MouseEvent) {
    this.onInfo.emit(e);
  }

  getActions(): IMenuButtonAction[] {
    if (!this.model()) {
      return [];
    }
    const actions: any[] = [];
    const app = this.model();
    const nws = app.NameWithoutSpaces;


    if (this.isOwner()) {
      // Edit
      actions.push({text: 'Edit', link: `/portal/products/${nws}/edit`});
      // Add new release
      if (app.Published) {
        actions.push({text: 'Add new release', link: '/portal/products/' + app.NameWithoutSpaces + '/releases'});
      }
      actions.push('View reviews');
      actions.push('Analytics');
      if (app.Published) {
        actions.push('Unpublish product');
      }

      if (app.Published === 1 && ConfigService.isContestActive() && app.sended !== 1) {
        actions.push('Apply for the contest');
      }
    }

    if (this.auth.isAdmin) {
      actions.push('Delete product');
    }
    return actions;
  }

  onAction(action: IMenuButtonAction) {
    const app = this.model();
    const text = action.item.text || action.item;
    const nws = app.NameWithoutSpaces;
    switch (text) {
      case 'Edit':
        void this.router.navigateByUrl(action.item.link);
        return;
      case 'View reviews':
        void this.router.navigateByUrl(`/portal/products/${nws}/reviews`);
        return;
      case 'Analytics':
        void this.router.navigateByUrl(`/portal/products/${nws}/analytics`);
        return;
      case 'Add new release':
        void this.router.navigateByUrl(action.item.link);
        return;
      case 'Delete product':
        this.askForDeletion(app, app.PD === 1);
        return;
      case 'Unpublish product':
        this.askForUnpublish(app);
        return;
      case 'Apply for the contest':
        this.sendToContest(app);
        return;
    }
  }

  sendToContest(app: IPackageData) {
    this.ds.sendToContest(app);
  }

  askForDeletion(app: IPackageData, isPDGrid: boolean) {
    const m = this.modal.show(<ModalData>{
      title: 'Confirmation',
      cancel: true,
      message: 'Are you sure you want to delete this ' + (isPDGrid ? 'product' : 'application') + '?',
      buttons: [
        { text: 'No', cancel: true },
        {
          text: 'Yes', default: true, clickOnEnter: true, cancel: true, click: _ => {
            this.deletePackage(app, isPDGrid);
          }
        }
      ]
    });
  }

  /**
   * Deletes package
   */
  deletePackage(app: IPackageData, isPDGrid: boolean) {
    this.ps.setProgress(true);
    this.http.request(
      '/mpapi/packages/delete',
      'post',
      { id: app.ID }
    )
      .then(data => {
        this.modal.show((isPDGrid ? 'Product' : 'Application') + ' was successfully deleted');
        void this.router.navigateByUrl('/');
      })
      .catch(e => {
        this.modal.show(this.http.getErrorText(e));
      })
      .finally(() => {
        this.ps.setProgress(false);
        this.cd.detectChanges();
      });
  }

  askForUnpublish(app: IPackageData) {
    const m = this.modal.show(<ModalData>{
      title: 'Confirmation',
      cancel: true,
      message: 'Are you sure you want to unpublish this package?',
      buttons: [
        {text: 'No', cancel: true},
        {
          text: 'Yes', default: true, cancel: true, click: _ => {
            this.unpublishPackage(app);
          }
        }
      ]
    });
  }

  unpublishPackage(app: IPackageData) {
    this.ps.setProgress(true);
    this.http.request(
      '/mpapi/packages/unpublish',
      'post',
      {id: app._id || app.ID}
    )
      .then(data => {
        this.modal.show('Package was successfully unpublished');
        this.model().Published = 0;
        this.cd.detectChanges();
      })
      .catch(e => this.showError(e))
      .finally(() => {
        this.cd.detectChanges();
        this.ps.setProgress(false);
      });
  }

  showError(e: any) {
    try {
      const txt = e.error.summary.replace(/\?/g, '');
      this.modal.show(txt);
    } catch (er) {
      console.error(`Can't save package: `, e);
    }
  }

  isOwner() {
    if (!this.auth.isLogged) {
      return false;
    }
    return this.auth.getUser() === this.model()?.UserID?.login;
  }

  isCompanyOwner() {
    const comp = this.auth.getUserCompanies();
    if (!comp?.length) {
      return false;
    }
    return comp.some(c => c.id === this.model().PublisherID?._id);
  }

  ngOnDestroy() {
    this.subLogin?.unsubscribe();
  }

  update() {
    this.cd.detectChanges();
  }
}
