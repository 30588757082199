import {AuthService} from '../../services/authService';
import { inject, Injectable, LOCALE_ID } from '@angular/core';
import {DatePipe, Location} from '@angular/common';
import {ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot} from '@angular/router';
import {Meta} from '@angular/platform-browser';
import {Observable} from 'rxjs/internal/Observable';
import {Status, TileBaseComponent} from '../../controls/tile/tile.base';
import {IPackageCollaborator, IPackageData} from '../../services/packageTypes';
import {OEXMarkdown} from '../../services/markdown';
import {PartnersDirectoryService} from '../../services/partnersDirectory';
import {APIService} from '../../services/api.service';
import {SiteTrackerService} from '../../services/site-tracker.service';
import {ROUTE_MARKETPLACE} from '../../services/global-types';

export const resolvePackageData: ResolveFn<IPackageData> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(PackageDetailsResolver).resolve(route, state);
};

@Injectable({
  providedIn: 'root'
})
export class PackageDetailsResolver {
  private api = inject(APIService);
  private auth = inject(AuthService);
  private meta = inject(Meta);
  private st = inject(SiteTrackerService);
  private router = inject(Router);
  private location = inject(Location);
  private pd = inject(PartnersDirectoryService);
  private locale = inject(LOCALE_ID);

  public model!: IPackageData;
  private route?: ActivatedRouteSnapshot;
  private getStatus: Function = TileBaseComponent.getTileStatus.bind(this);
  private datePipe: DatePipe;
  private isPortal = false;

  constructor() {
    const locale = this.locale;

    this.datePipe = new DatePipe(locale);
  }

  /**
   * Parses readme from github
   */
  static parseGithubReadme(model: any) {
    // Check if we need load github readme
    if (!model.FullDescriptionGitCheck) {
      const result = OEXMarkdown.parse(model.FullDescription);
      model.FullDescriptionMd = result;
      return;
    } else {
      if (model.FullDescriptionMd) {
        model.FullDescriptionMd = OEXMarkdown.parseGit(model.FullDescriptionMd);
      }
    }
  }

  static isGithub(url: string) {
    return url.toLowerCase().includes('github.com');
  }

  /**
   * Returns github API Url from github repo url
   * @param githubUrl {string}
   */
  static getGithubAPIUrl(githubUrl: string): string {
    if (!githubUrl) {
      return '';
    }
    if (PackageDetailsResolver.isGithub(githubUrl)) {
      const prj = githubUrl.split('/').slice(-2).join('/');
      return 'https://api.github.com/repos/' + prj;
    } else {
      let prj = githubUrl.split('/').slice(3).join('/');
      if (prj.charAt(prj.length - 1) === '/') {
        prj = prj.slice(0, -1);
      }
      prj = encodeURIComponent(prj);
      return `https://gitlab.com/api/v4/projects/${prj}`;
    }
  }

  /**
   * Route resolver
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any>}
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    this.isPortal = state.url.split('/')[1] === 'portal';
    const nws = route.params.id;
    this.model = {
      invalid: false,
      _id: nws === 'new' ? 'new' : undefined,
      Industries: '',
      issues: [],
      CategoryID: {
        _id: null
      },
      UserID: {
        _id: null
      },
      Name: '',
      Description: '',
      DemoURL: '',
      FullDescription: '',
      FullDescriptionGitCheck: !this.pd.enabled,
      ReleaseNotes: '',
      ReleaseNotesMd: '',
      DCURLs: [{url: ''}],
      VideoURLs: [{url: ''}],
      // versions: '',
      lastNotes: '',
      licenseType: '',
      licenseLink: '',
      ByCompany: null,
      Stars: 0,
      Follows: 0,
      IsStar: false,
      IsFollow: false,
      sended: 0,
      ChildNWS: '',
      previousModel: null,
      Tag: [],
      Action: {
        _id: '1'
      }
    } as any;

    this.route = route;

    const res = new Observable<any>(o => {
      const done = () => {
        o.next(this.model);
        o.complete();
      };
      if (nws === 'new') {
        // For non logged users redirect from package creation
        if (!this.auth.isLogged) {
          this.model.invalid = true;
          this.model._id = '';
          done();
          return;
        }

        done();
        return;
      }
      // Load package data
      this.requestData(nws)
        .finally(done);
    });
    return res;
  }

  /**
   * Fills owner property isOwner for package
   */
  fillOwner() {
    this.model.isOwner = false;
    const user = this.auth.getUser();
    if (user && this.model && this.model.UserID && this.model.UserID.login) {
      this.model.isOwner = this.model.UserID.login === this.auth.getUser();
    }
  }

  /**
   * Request package data
   * @returns {Promise}
   */
  requestData(nws: string) {
    return new Promise((resolve: any, rej) => {
      nws = encodeURIComponent(nws.replace(/%20/g, '%09'));
      if (nws === 'new') {
        return;
      }

      const applyModel = (data, previousData?: IPackageData) => {
        /*for (let i = 0; i < 6; i++) {
          data.collaborators.push({
             avatar: '',
            accepted: true,
            individualKey: '',
            firstName: String.fromCharCode(65 + Math.round(Math.random() * 20)) + '_test_name',
            is_owner: false,
            invite_send_datetime: '',
            id: 999 + i
          } as IPackageCollaborator)
        }*/
        this.model = structuredClone(data);
        if (previousData) {
          this.model.previousModel = JSON.parse(JSON.stringify(previousData));
        } else {
          this.model.previousModel = JSON.parse(JSON.stringify(data));
        }
        this.model.ReleaseNotesMd = OEXMarkdown.parse(this.model.ReleaseNotes || '');
        this.fillOwner();
        this.fillArrays();
        if (!this.checkPackageAccess()) {
          this.model.invalid = true;
          resolve();
          return;
        }
        if (this.model.Tag && (typeof this.model.Tag === 'string')) {
          this.model.Tag = this.model.Tag.split(',').map(t => t.trim());
        }
        this.convertDates();
        this.updateMetaTags();
        this.parseVersions();
        PackageDetailsResolver.parseGithubReadme(this.model);
        resolve();
      };

      this.api.getPackageData(nws)
        .then(async parent => {
          if (parent.redirectNWS) {
            void this.router.navigateByUrl('/' + (this.st.isMarketplace ? ROUTE_MARKETPLACE : '') + '/package/' + parent.redirectNWS);
            rej();
            return;
          }
          if (!this.route?.data.ignoreChildNWS && parent.ChildNWS && this.isPortal) {
            let child = await this.api.getPackageData(parent.ChildNWS);
            applyModel(child, parent);
          } else {
            applyModel(parent);
          }
        })
        .catch(e => {
          this.model.invalid = true;
          resolve();
        });
    });
  }

  /**
   * Checks rights of user to see this package
   * @returns {boolean} True if allowed
   */
  checkPackageAccess(): boolean {
    const status = this.getStatus(this.model);
    if (this.isPortal) {
      return this.auth.isAdmin || this.model?.UserID?.login === this.auth.getUser() ||
        this.auth.getUserCompanies()?.some(c => c.id?.toString() === this.model?.PublisherID?._id?.toString());
    }
    if (status === Status.Published) {
      return true;
    }
    if (!this.model.UserID) {
      return true;
    }
    if (this.auth.isAdmin) {
      return true;
    }
    return this.model.UserID.login === this.auth.getUser();
  }

  /**
   * Updates meta tags with app info
   */
  updateMetaTags() {
    this.meta.addTag({name: 'og:title', property: 'og:title', content: this.model.Name});
    this.meta.addTag({name: 'og:description', property: 'og:description', content: this.model.Description});

    this.meta.addTag({name: 'og:url', property: 'og:url', content: this.location.path()});
    this.meta.addTag({name: 'og:url', property: 'og:url', content: this.location.path()});
    const imageUrl = this.model?.NameWithoutSpaces ? `/mpapi/img/page-preview/package/${this.model?.NameWithoutSpaces}` : '';
    this.meta.addTag({name: 'og:image', property: 'og:image', content: imageUrl});
    this.meta.addTag({name: 'og:image:secure_url', property: 'og:image:secure_url', content: imageUrl});
  }

  /**
   * Loads version history
   */
  parseVersions() {
    const list = this.model.Versions;
    let v = '';
    this.model.lastNotes = '';
    // Generate text for markdown
    list.forEach(i => {
      // Cut last comma for two digits versions
      if (i.ver[i.ver.length - 1] === '.') {
        i.ver = i.ver.slice(0, -1);
      }
      if (i.approvalDate) {
        const date = this.datePipe.transform(i.approvalDate.replace(' ', 'T'), 'dd MMM, yyyy') ?? '';
        i.approvalDateUI = date;
        v += `### ${i.ver}\n### Released: ${date}\n`;
      } else {
        v += `## ${i.ver}\n`;
      }
      v += i.notes;
      v += '\n';
      // Fill last version notes
      if (!this.model.lastNotes) {
        this.model.lastNotes = i.notes;
      }
    });
    // this.model.versions = OEXMarkdown.parse(v || '');
    if (this.model.lastNotes) {
      this.model.lastNotes = OEXMarkdown.parse(this.model.lastNotes || '');
    }
  }

  private fillArrays() {
    if (this.model.VideoURLs.length === 0) {
      this.model.VideoURLs = [{url: ''}];
    }
    if (this.model.DCURLs.length === 0) {
      this.model.DCURLs = [{url: ''}];
    }
    if (this.model.previousModel) {
      if (this.model.previousModel.VideoURLs.length === 0) {
        this.model.previousModel.VideoURLs = [{url: ''}];
      }
      if (this.model.previousModel.DCURLs.length === 0) {
        this.model.previousModel.DCURLs = [{url: ''}];
      }
    }
  }

  private convertDates() {
    if (this.model.FirstApprovalDate) {
      this.model.FirstApprovalDate = this.datePipe.transform(this.model.FirstApprovalDate.replace(' ', 'T'), 'dd MMM, yyyy') ?? '';
    }
    if (this.model.QualityCheck?.LastDate) {
      this.model.QualityCheck.LastDate = this.datePipe.transform(this.model.QualityCheck.LastDate.replace(' ', 'T'), 'dd MMM, yyyy') ?? '';
    }
    if (this.model.QualityCheck) {
      let status = '';
      // TODO: receive status text from backend
      switch (this.model.QualityCheck?.WorkingStatus) {
        case 0:
          status = 'Doesn\'t work';
          break;
        case 1:
          status = 'Works';
          break;
        case 2:
          status = 'Impossible to Test';
          break;
      }
      this.model.QualityCheck.WorkingStatusUI = status;
    }
  }
}
