<!-- Progress spinner -->
@if (isLoading) {
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
}

@if (!isLoading) {
  @for (v of data; track v; let idx = $index) {
    <oex-card
      [collapsible]="true"
      [collapsed]="idx !== 0"
      >
      <!-- Header -->
      <div class="header" ngProjectAs="header">
        Version: {{v.version}} <span class="txt-count">Dependencies ({{v.dependencies.length || 0}})</span>
      </div>
      <!-- Items -->
      @for (d of v.dependencies; track d) {
        <div class="dep-item">
          <!-- Package name link -->
          <strong>
            @if (d.nws) {
              <a class="mp-link secondary" [routerLink]="'/package/' + d.nws">{{d.package_name}}</a>
            } @else {
              {{d.package_name}}
            }
            <ng-template #noLink>{{d.package_name}}</ng-template>
          </strong>
          <!-- Company/User name link -->
          @if (d.user_key && d.user_name) {
            <span>&nbsp;by&nbsp;</span>
            <a class="mp-link secondary" [routerLink]="'/user/' + d.user_name + '/' + d.user_key">{{d.user_name}}</a>
          }
        </div>
      }
    </oex-card>
  }
}





<!-- Version column -->
<!--<div class="col1">
<label>Version</label>
<mat-select [(ngModel)]="versionIdx" name="Version">
  <mat-option *ngFor="let v of data; let idx=index" [value]="idx">{{ v.version }}</mat-option>
</mat-select>
</div>-->

<!-- Dependencies column -->
<!-- <div class="col2">
<label>Dependencies ({{data[versionIdx].dependencies?.length || 0}})</label>
<div class="dep-item" *ngFor="let d of data[versionIdx].dependencies">
  &lt;!&ndash; Package name link &ndash;&gt;
  <strong>
    <ng-container *ngIf="d.nws; else noLink">
      <a class="mp-link" [routerLink]="'/package/' + d.nws">{{d.package_name}}</a>
    </ng-container>
    <ng-template #noLink>{{d.package_name}}</ng-template>
  </strong>

  &lt;!&ndash; Company/User name link &ndash;&gt;
  <ng-container *ngIf="d.user_key && d.user_name">
    <span>&nbsp;by&nbsp;</span>
    <a class="mp-link" [routerLink]="'/user/' + d.user_name + '/' + d.user_key">{{d.user_name}}</a>
  </ng-container>
</div>
</div>-->

